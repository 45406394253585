<template>
  <v-card :loading="loading">
    <v-card-title class="primary white--text mb-4">
      <v-row class="d-flex align-center">
        <v-col cols="12" sm="9">
          <h1 class="text-h5">{{ fund.shortName || fund.name }}</h1>
          <h2 class="text-h6 font-weight-light">CNPJ: {{ fund.document }}</h2>
        </v-col>
        <v-col class="d-flex justify-space-between align-center">
          <v-chip v-if="!fund.returns || !fund.returns.equity" color="red" dark small>
            <v-icon small left color="white">mdi-alert-outline</v-icon>
            Informação Parcial
          </v-chip>

          <FundHealth v-if="shouldShowFundHealth" :fundId="fund._id" />

          <UpsertQuotaForm
            v-if="shouldShowUpsertQuota"
            :fund="fund"
            :fundHistory="fundHistory"
            :is-fetching-data="loading"
            @update="quotaUpdated"
            @insert="quotaInserted"
          />
          <FundEditForm v-if="shouldShowEditFund" :fund="fund" @update="fundUpdated" />
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col v-for="(table, index) in tables" :key="index">
          <v-simple-table>
            <tbody>
              <tr v-for="row in table" :key="row.label">
                <td>{{ row.label }}</td>
                <td v-if="row.linkTo">
                  <router-link class="no-link-decoration" :to="row.linkTo" target="_blank">
                    {{ row.value }}
                  </router-link>
                  <router-link class="no-link-decoration" :to="row.linkTo" target="_blank">
                    <v-btn icon small>
                      <v-icon small>mdi-open-in-new</v-icon>
                    </v-btn>
                  </router-link>
                </td>
                <td v-else>{{ row.value }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-simple-table class="text-center" v-if="hasFoundHistory">
      <thead>
        <tr>
          <th class="primary white-border text-center white--text">
            {{ formatDate(fund.history.date) }}
          </th>
          <th class="primary white-border text-center white--text">MTD</th>
          <th class="primary white-border text-center white--text">6M</th>
          <th class="primary white-border text-center white--text">12M</th>
          <th class="primary white-border text-center white--text">24M</th>
          <th class="primary white-border text-center white--text">YTD</th>
          <th class="primary white-border text-center white--text">BTD</th>
          <th class="primary white-border text-center white--text">VOL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td :class="textColor(fund.history.performance)">
            {{ formatPerformance(fund.history.performance) }}
          </td>
          <td :class="textColor(fund.history.mtd)">{{ formatPerformance(fund.history.mtd) }}</td>
          <td :class="textColor(fund.history.m6)">{{ formatPerformance(fund.history.m6) }}</td>
          <td :class="textColor(fund.history.m12)">{{ formatPerformance(fund.history.m12) }}</td>
          <td :class="textColor(fund.history.m24)">{{ formatPerformance(fund.history.m24) }}</td>
          <td :class="textColor(fund.history.ytd)">{{ formatPerformance(fund.history.ytd) }}</td>
          <td :class="textColor(fund.history.btd)">{{ formatPerformance(fund.history.btd) }}</td>
          <td>{{ formatPercentage(fund.history.volEWMA) }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<style lang="scss" scoped>
.no-link-decoration {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>

<script>
import {
  formatDate,
  formatMoney,
  formatNumber,
  formatPercentage,
  formatPerformance,
  formatQuota,
} from '@/utils/format-utils';

import FundEditForm from './FundEditForm.vue';
import FundHealth from './FundHealth.vue';
import UpsertQuotaForm from './UpsertQuotaForm.vue';

export default {
  name: 'BasicInformation',

  components: {
    FundEditForm,
    FundHealth,
    UpsertQuotaForm,
  },

  props: {
    fund: {
      type: Object,
      default: () => ({}),
    },

    fundHistory: {
      type: Array,
      default: () => [],
    },

    user: {
      type: Object,
      default: () => ({}),
    },

    loading: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    shouldShowFundHealth: (vm) => ['admin', 'backoffice'].includes(vm.user.acl),
    shouldShowUpsertQuota: (vm) => ['admin', 'backoffice'].includes(vm.user.acl),
    shouldShowEditFund: (vm) => ['admin', 'backoffice'].includes(vm.user.acl),

    hasFoundHistory: (vm) => vm.fund?.history && Object.keys(vm.fund.history).length,
    hasFoundFullHistory: (vm) => vm.fund?.lastFullHistory && Object.keys(vm.fund.lastFullHistory).length,

    tables() {
      return [
        [
          {
            label: 'Data registro',
            value: this.formatDate(this.fund?.registerDate),
          },
          {
            label: 'Data inicial',
            value: this.formatDate(this.fund?.initialDate),
          },
          {
            label: 'Gestor',
            value: this.fund?.manager?.name ?? '-',
            linkTo: this.fund?.manager ? `/managers/${this.fund.manager._id}` : '#',
          },
          {
            label: 'Administrador',
            value: this.fund?.administrator?.name ?? '-',
            linkTo: this.fund?.administrator
              ? `/administrators/${this.fund.administrator._id}`
              : '#',
          },
          {
            label: 'Auditor',
            value: this.fund?.auditor?.name ?? '-',
          },
        ],
        [
          {
            label: 'Classe',
            value: this.fund?.class ?? '-',
          },
          {
            label: 'Benchmark',
            value: this.fund?.benchmark?.name ?? '-',
          },
          {
            label: `Patrimônio em ${this.formatDate(this.fund?.lastFullHistory?.date)}`,
            value: this.formatMoney(this.fund?.lastFullHistory?.equity),
          },
          {
            label: `Cotistas em ${this.formatDate(this.fund?.lastFullHistory?.date)}`,
            value: this.formatNumber(this.fund?.lastFullHistory?.quotaholders),
          },
          {
            label: `Cota em ${this.formatDate(this.fund?.history?.date)}`,
            value: this.formatQuota(this.fund?.history?.quota),
          },
        ],
      ];
    },
  },

  methods: {
    formatDate,
    formatMoney,
    formatQuota,
    formatNumber,
    formatPercentage,
    formatPerformance,

    fundUpdated(fundData) {
      this.$emit('update:fund', fundData);
    },

    quotaUpdated(historyData) {
      this.$emit('update:quota', historyData);
    },

    quotaInserted(historyData) {
      this.$emit('insert:quota', historyData);
    },

    textColor(val) {
      const THRESHOLD = -0.8;
      const number = Number.parseFloat(val);

      if (!Number.isFinite(number) || number < THRESHOLD || number === 0) {
        return '';
      }

      if (number > 0) {
        return 'green--text';
      }

      return 'red--text';
    },
  },
};
</script>
