<template>
  <v-dialog v-model="dialog" max-width="580">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon color="white">mdi-timeline-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary justify-center white--text">
        Inserir ou Editar Cotas
        <v-btn icon small class="position-absolute" @click="close">
          <v-icon small color="primary darken-2">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-progress-linear
        class="mb-4"
        color="#9d6c32"
        height="4"
        indeterminate
        :active="loading || isFetchingData"
      />
      <v-card-text>
        <v-data-table
          dense
          hide-default-footer
          sort-by="date"
          :headers="headers"
          :items="editableFundHistory"
        >
          <template v-slot:item.date="{ item }">
            {{ formatDate(item.date) }}
          </template>

          <template v-slot:item.quota="{ item, index }">
            <v-form
              v-if="editingIndex === index"
              v-model="isQuotaValid"
              @submit.prevent="patchFundHistory"
            >
              <numeric-input
                dense
                hide-details
                ref="quota"
                placeholder="Cota"
                class="tabular-input"
                :options="vOptions.positive"
                :rules="[vRules.requiredField, vRules.positive]"
                :value="supportFields.quota"
                @input="supportFields.quota = vMask.unmaskNumber($event)"
              />
            </v-form>
            <span v-else>
              {{ formatQuota(item.quota) }}
            </span>
          </template>

          <template v-slot:item.source="{ item }">
            {{ item.source }}
          </template>

          <template v-slot:item.actions="{ item, index }">
            <div class="d-flex justify-center" v-if="editingIndex === index">
              <v-btn
                icon
                small
                type="submit"
                color="success"
                :disabled="!isFormValid"
                @click="patchFundHistory"
              >
                <v-icon small>mdi-check-bold</v-icon>
              </v-btn>
              <v-btn icon small color="error" @click="reset">
                <v-icon small>mdi-close-thick</v-icon>
              </v-btn>
            </div>
            <v-btn
              v-else
              icon
              small
              color="primary"
              :disabled="isEditing || isCreating"
              @click="() => editQuota(index, item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <template v-slot:body.append v-if="isCreating">
            <tr>
              <td>
                <v-form v-model="isDateValid" @submit.prevent="createFundHistory">
                  <date-picker-field-v2
                    dense
                    autofocus
                    hide-details
                    placeholder="Data"
                    class="tabular-input"
                    :picker-props="{ allowedDates: isAllowedDate }"
                    :rules="[vRules.requiredField, vRules.validDate, vRules.allowedDate]"
                    v-model="supportFields.date"
                  />
                </v-form>
              </td>
              <td>
                <v-form v-model="isQuotaValid" @submit.prevent="createFundHistory">
                  <numeric-input
                    dense
                    hide-details
                    placeholder="Cota"
                    class="tabular-input"
                    :options="vOptions.positive"
                    :rules="[vRules.requiredField, vRules.positive]"
                    :value="supportFields.quota"
                    @input="supportFields.quota = vMask.unmaskNumber($event)"
                  />
                </v-form>
              </td>
              <td class="text-center">
                manual
              </td>
              <td>
                <v-btn
                  small
                  icon
                  type="submit"
                  color="success"
                  :disabled="!isFormValid"
                  @click="createFundHistory"
                >
                  <v-icon small>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn color="error" @click="reset" small icon>
                  <v-icon small>mdi-close-thick</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-btn
          shaped
          small
          class="mt-4"
          width="100%"
          color="primary"
          :loading="loading || isFetchingData"
          :disabled="isEditing || isCreating"
          @click="addRow"
        >
          <v-icon left>mdi-table-row-plus-after</v-icon>
          Inserir
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatDate, formatQuota } from '@/utils/format-utils';
import { unmaskNumber } from '@/utils/mask-utils';
import { requiredField, validDate } from '@/utils/validators';

import DatePickerFieldV2 from '@/components/global/DatePickerFieldV2.vue';
import NumericInput from '@/components/global/NumericInput.vue';
import api from '@/services/api';
import moment from 'moment-loyall';

export default {
  props: {
    fund: {
      type: Object,
      default: () => ({}),
    },

    fundHistory: {
      type: Array,
      default: () => [],
    },

    isFetchingData: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DatePickerFieldV2,
    NumericInput,
  },

  data: (vm) => ({
    supportFields: { quota: null, date: null },

    dialog: false,
    loading: false,

    isQuotaValid: false,
    isDateValid: false,
    editingIndex: null,

    editableHistoryLimit: 10,

    vRules: {
      requiredField,
      validDate,
      allowedDate: (v) => vm.isAllowedDate(moment.utc(v)),
      positive: (v) => unmaskNumber(v) > 0,
    },

    vMask: {
      unmaskNumber,
    },

    vOptions: {
      positive: {
        valueRange: { min: 0 },
      },
    },

    headers: [
      {
        text: 'Data',
        value: 'date',
        align: 'center',
        sortable: false,
        width: '160px',
      },
      {
        text: 'Cota',
        value: 'quota',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Origem',
        value: 'source',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Editar',
        value: 'actions',
        align: 'center',
        sortable: false,
        width: '100px',
      },
    ],
  }),

  computed: {
    isEditing: (vm) => vm.editingIndex !== null && !vm.isCreating,
    isCreating: (vm) => vm.editingIndex === vm.editableFundHistory.length,
    isFormValid: (vm) => (vm.isEditing && vm.isQuotaValid)
      || (vm.isCreating && vm.isQuotaValid && vm.isDateValid),

    editableFundHistory: (vm) => vm.fundHistory
      .slice(0, vm.editableHistoryLimit)
      .sort((a, b) => new Date(a.date) - new Date(b.date)),
  },

  methods: {
    formatDate,
    formatQuota,

    addRow() {
      this.editingIndex = this.editableFundHistory.length;
    },

    editQuota(index, item) {
      this.editingIndex = index;
      this.supportFields.quota = item.quota;
      // Autofocus isn't suitable: quota needs to be set before focusing
      this.$nextTick(() => this.$refs.quota.focus());
    },

    reset() {
      this.supportFields = { quota: null, date: null };
      this.editingIndex = null;
    },

    close() {
      this.dialog = false;
    },

    isAllowedDate(momentDate) {
      const now = moment.utc();
      const date = moment.utc(momentDate);
      const dates = this.editableFundHistory.map((el) => moment.utc(el.date));
      const minDate = now.businessSubtract(this.editableHistoryLimit, 'days', 'brasil');

      const rules = [
        date.isSameOrBefore(now, 'day'),
        date.isAfter(minDate, 'day'),
        date.isBusinessDay('brasil'),
        !dates.some((el) => el.isSame(date, 'day')),
      ];

      return rules.every((el) => el);
    },

    async patchFundHistory() {
      if (!this.isFormValid) return;

      this.loading = true;

      try {
        const { _id, date } = this.editableFundHistory[this.editingIndex];
        const { quota } = this.supportFields;

        // Delete and create instead of patching due to bugs in update endpoint:
        // performance recalculation is not triggered and source can't be edited
        await api.funds.histories.deleteById(_id);
        const { data } = await api.funds.histories.create({
          fundId: this.fund._id,
          date: date.slice(0, 10),
          quota,
        });

        this.$emit('update', data);
        this.reset();
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response?.data.message ?? error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },

    async createFundHistory() {
      if (!this.isFormValid) return;

      this.loading = true;

      try {
        const { _id: fundId } = this.fund;

        const { data } = await api.funds.histories.create({
          fundId,
          ...this.supportFields,
        });
        this.$emit('insert', data);
        this.reset();
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response?.data.message ?? error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .tabular-input input {
    text-align: center;
    font-size: 0.875rem;
  }
}

.position-absolute {
  position: absolute;
  top: 2px;
  right: 2px;
}
</style>
