<template>
  <div>
    <h2 class="text-subtitle-1 mt-3 mb-3">Patrimônio Médio por Cotista x Qtde Cotistas </h2>
    <v-card :loading="loading" class="mt-3">
      <v-card-text class="pa-0">
        <apexchart width="100%" height="400" type="area" :options="options" :series="series"></apexchart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import moment from 'moment-loyall';
import pt from 'apexcharts/dist/locales/pt-br.json';
import api from '@/services/api';

export default {
  props: {
    fundId: String,
    benchmarkId: String,
    benchmarkName: String,
    endDate: String,
    startDate: String,
  },
  data: () => ({
    series: [],
    history: [],
    withdraw: [],
    raising: [],
    dataCategory: [],
    benchmarkHistoryData: [],
    loading: true,
    options: {
      chart: {
        locales: [pt],
        defaultLocale: 'pt-br',
        animations: {
          enabled: false,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: false,
          },
        },
        zoom: {
          type: 'x',
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        line: {
          curve: 'smooth',
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: 'full',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          // opacityFrom: 0.5,
          // opacityTo: 0,
          // stops: [0, 90, 100],
        },
      },
      stroke: {
        show: true,
        curve: 'straight',
        // lineCap: 'butt',
        // colors: undefined,
        width: 2,
      },
      yaxis: [
        {
          title: {
            text: 'PL Médio',
          },
          labels: {
            formatter: (val) => String(parseFloat(val).toFixed(0)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
          },
        },
        {
          opposite: true,
          title: {
            text: 'Cotistas',
          },
          labels: {
            formatter: (val) => parseFloat(val).toFixed(0),
          },
        },
      ],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'MM/yyyy',
            month: 'MM/yyyy',
            day: 'dd/MM/yyyy',
            hour: 'HH:mm',
          },
        },
      },
      tooltip: {
        shared: true,
        x: {
          show: true,
          format: 'dd/MM/yyyy',
          // format: 'dd/MM/yyyy HH:mm',
          formatter: undefined,
        },
        y: [
          {
            formatter(val) {
              return `R$ ${String(val.toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
            },
          },
          {
            formatter(val) {
              return `${val}`;
            },
          },
        ],
      },
    },
  }),
  watch: {
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
  },
  methods: {
    updateChart() {
      const { withdraw } = this;
      const { raising } = this;
      this.series = [
        {
          data: withdraw,
          name: 'Patrimônio Médio',
        },
        {
          data: raising,
          name: 'Cotistas',
        },
      ];
    },
    async getData() {
      try {
        if (!this.fundId) return;
        this.loading = true;
        this.withdraw = [];
        this.raising = [];
        // Get Fund information
        const {
          data: { history: fundHistory },
        } = await api.funds.returnsById(this.fundId, {
          params: {
            endDate: this.endDate,
            startDate: this.startDate,
          },
        });

        fundHistory.sort((a, b) => new Date(a.date) - new Date(b.date));
        let raisings = new Decimal(0);
        fundHistory.forEach((hist) => {
          if (!hist.equity || hist.quotaholders === 0) return;
          if (hist.raising) raisings = raisings.add(hist.raising);

          if (Number.isNaN(raisings)) return;

          this.withdraw.push({
            x: moment.tz(hist.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
            y: new Decimal(hist.equity ?? '0').dividedBy(hist.quotaholders ?? '1'),
          });

          this.raising.push({
            x: moment.tz(hist.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
            y: hist.quotaholders,
          });
        });
        this.updateChart();
        this.loading = false;
      } catch (error) {
        throw Error(error);
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
