<template>
  <div>
    <h2 class="text-subtitle-1 mt-3 mb-3">Drawdown</h2>
    <v-card :loading="loading" class="mt-3">
        <apexchart
          width="100%"
          height="300"
          type="area"
          :options="options"
          :series="series"
        ></apexchart>
    </v-card>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import moment from 'moment-loyall';
import pt from 'apexcharts/dist/locales/pt-br.json';
import api from '@/services/api';

export default {
  props: {
    fundId: String,
    benchmarkId: String,
    benchmarkName: String,
    endDate: String,
    startDate: String,
  },
  data: () => ({
    series: [],
    drawdown: [],
    loading: true,
    options: {
      chart: {
        locales: [pt],
        defaultLocale: 'pt-br',
        zoom: {
          type: 'x',
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: 'straight',
        // lineCap: 'butt',
        // colors: undefined,
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        // size: 0,
        style: 'full',
      },
      yaxis: {
        // tickAmount: 10,
        max: 0,
        floating: false,
        // tickAmount: 10000,
        // logarithmic: true,
        title: {
          text: '',
        },
      },
      tooltip: {
        shared: true,
        x: {
          show: true,
          format: 'dd/MM/yyyy',
          formatter: undefined,
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'MM/yyyy',
            month: 'MM/yyyy',
            day: 'dd/MM/yyyy',
            hour: 'HH:mm',
          },
        },
      },
    },
  }),
  watch: {
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      try {
        if (this.fundId) {
          this.loading = true;
          const {
            data: { drawdown },
          } = await api.funds.drawdownById(this.fundId, {
            params: {
              startDate: this.startDate,
              endDate: this.endDate,
            },
          });
          const filterDrawdown = drawdown.map((a) => ({
            date: a.date,
            drawdown: (new Decimal(a.drawdown).comparedTo(0) === 1 ? '0' : a.drawdown),
          }));
          this.drawdown = filterDrawdown;
          this.drawdownFn();
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    drawdownFn() {
      const data = [];
      this.drawdown.sort((a, b) => new Date(b.date) - new Date(a.date));
      this.drawdown.forEach((input) => {
        const draw = new Decimal(input.drawdown).mul(100).toFixed(2);
        data.push({
          x: moment.tz(input.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
          y: draw,
        });
      });

      this.series = [
        {
          data,
          name: 'Drawdown',
        },
      ];
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
