<template>
  <div>
    <h2 class="text-subtitle-1 mt-3 mb-3">Gráfico de Volatilidade</h2>
    <v-card :loading="loading" class="mt-3">
      <apexchart
        width="100%"
        height="300"
        type="area"
        :options="options"
        :series="series"
      ></apexchart>
    </v-card>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import moment from 'moment-loyall';
import pt from 'apexcharts/dist/locales/pt-br.json';
import api from '@/services/api';

export default {
  props: {
    fundId: String,
    benchmarkId: String,
    benchmarkName: String,
    endDate: String,
    startDate: String,
  },
  data: () => ({
    series: [],
    volatility: [],
    loading: true,
    options: {
      chart: {
        locales: [pt],
        defaultLocale: 'pt-br',
        zoom: {
          type: 'x',
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      stroke: {
        show: true,
        curve: 'straight',
        // lineCap: 'butt',
        // colors: undefined,
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        // size: 0,
        style: 'full',
      },
      yaxis: {
        // tickAmount: 10,
        // max: 0,
        floating: false,
        // tickAmount: 10000,
        // logarithmic: true,
        title: {
          text: '%',
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'MM/yyyy',
            month: 'MM/yyyy',
            day: 'dd/MM/yyyy',
            hour: 'HH:mm',
          },
        },
      },
    },
  }),
  watch: {
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      try {
        if (this.fundId) {
          this.loading = true;
          const {
            data: { volatility },
          } = await api.funds.volatilityById(this.fundId, {
            params: {
              endDate: this.endDate,
              startDate: this.startDate,
            },
          });
          this.volatility = volatility;
          this.volatilityFn();
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    volatilityFn() {
      const data = [];
      this.volatility.sort((a, b) => new Date(a.date) - new Date(b.date));
      this.volatility.forEach((input) => {
        const draw = new Decimal(input.volatility).mul(100).toFixed(2);
        data.push({
          x: moment(input.date).format('YYYY-MM-DD'),
          y: draw,
        });
      });

      this.series = [
        {
          data,
          name: 'Volatilidade',
        },
      ];
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
