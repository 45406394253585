<template>
  <v-container>
     <v-row dense class="mb-3 overview-row">
        <v-col class="col-5 col-md">
            <v-card :loading="loading" color="primary darken-2"
            class="py-2 text-center text-h4 white--text font-weight-black"
            >
                {{ numberWithCommas(overview.total) }}
                <v-progress-linear color="primary darken-1" value="100"></v-progress-linear>
                <span class="font-weight-light text-subtitle-1 d-block">
                  Total
                </span>
            </v-card>
          </v-col>
          <v-col class="col-5 col-md">
              <v-card :loading="loading"
              color="green darken-3 text-center py-2
              text-h4 white--text font-weight-black">
                  {{ numberWithCommas(overview.total - overview.canceled) }}
                  <v-progress-linear color="green darken-1"
                    :value="((overview.total - overview.canceled)/overview.total) * 100">
                  </v-progress-linear>
                  <span class="font-weight-light text-subtitle-1 d-block">
                    Operantes
                  </span>
              </v-card>
          </v-col>
          <v-col class="col-5 col-md">
              <v-card :loading="loading"
              color="red darken-3 text-center py-2
              text-h4 white--text font-weight-black">
                  {{ numberWithCommas(overview.canceled) }}
                  <v-progress-linear color="red darken-1"
                    :value="(overview.canceled/overview.total) * 100">
                  </v-progress-linear>
                  <span class="font-weight-light text-subtitle-1 d-block">
                    Cancelados
                  </span>
              </v-card>
          </v-col>
          <v-col class="col-5 col-md">
              <v-card :loading="loading" color="grey darken-2"
              class="py-2 text-center text-h4 white--text font-weight-black"
              >
                {{ numberWithCommas(overview.exclusive) }}
                <v-progress-linear color="grey darken-1"
                  :value="(overview.exclusive/overview.total) * 100">
                </v-progress-linear>
                <span class="font-weight-light text-subtitle-1 d-block">
                  Exclusivos
                </span>
              </v-card>
          </v-col>
          <v-col class="col-5 col-md">
              <v-card :loading="loading" color="cyan darken-2"
              class="py-2 text-center text-h4 white--text font-weight-black"
              >
                {{ numberWithCommas(overview.longTerm) }}
                <v-progress-linear color="cyan darken-1"
                  :value="(overview.longTerm/overview.total) * 100">
                </v-progress-linear>
                <span class="font-weight-light text-subtitle-1 d-block">
                  Longo Prazo
                </span>
              </v-card>
          </v-col>
          <v-col class="col-5 col-md">
              <v-card :loading="loading" color="deep-purple darken-4 "
                class="py-2 text-center text-h4 white--text font-weight-black"
              >
                {{ numberWithCommas(overview.qualifiedInvestor) }}
                <v-progress-linear color="deep-purple darken-1"
                  :value="(overview.qualifiedInvestor/overview.total) * 100">
                </v-progress-linear>
                <span class="font-weight-light text-subtitle-1 d-block">
                  Qualificado
                </span>
              </v-card>
          </v-col>
      </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="funds"
        sort-by="calories"
        class="elevation-1"
        :loading="loading"
        dense
        :mobile-breakpoint="0"
        :items-per-page="50"
        :footer-props="footerProps"
        :server-items-length="totalFunds"
        @update:page="changePage"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Fundos</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-layout>
              <v-chip
                :color="open ? 'primary' : 'grey'"
                :outlined="!open"
                @click="cancel ? open = !open : true"
                class="mx-1 py-1 px-2"
                small
              >Operantes</v-chip>
              <v-chip
                :color="cancel ? 'primary' : 'grey'"
                :outlined="!cancel"
                @click="open ? cancel = !cancel : true"
                class="mx-1 py-1 px-2"
                small
              >Cancelados</v-chip>
            </v-layout>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-search"
              label="Procurar"
              class="mr-5"
              single-line
              hide-details
              @input="isTyping = true"
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:item.name="{ item }">
          <router-link class="fund-link" :to="`/funds/${item._id}`">
            <span style="
                        overflow: hidden;
                        white-space: nowrap;
                        display: block;
                        text-overflow: ellipsis;">
                {{item.shortName || item.name}}
            </span>
          </router-link>
        </template>

        <template v-slot:item.manager="{ item }">
          <span style="
                      overflow: hidden;
                      white-space: nowrap;
                      display: block;
                      text-overflow: ellipsis;">
            {{item.manager ? item.manager.name : ''}}
          </span>
        </template>

        <template v-slot:item.administrator="{ item }">
          {{item.administrator ? item.administrator.name : ''}}
        </template>

        <!-- <template v-slot:item.benchmark="{ item }">
          <router-link :to="`/indexes/${item.benchmark._id}`">
            {{item.benchmark.name}}
          </router-link>
        </template> -->

        <template v-slot:item.startDate="{ item }">
          {{parseDate(item.registerDate)}}
        </template>

        <template v-slot:item.action="{ item }">
            <v-btn icon small :to="`/funds/${item._id}`">
                <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon small>
                <v-icon @click="editItem(item)">mdi-pencil</v-icon>
            </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<style scoped>
@media (max-width: 991px) {
  .overview-row {
    flex-wrap: nowrap;
    overflow-x: scroll;
  }
}
.fund-link {
  color: #000;
  text-decoration: none;
}
</style>

<script>
import moment from 'moment-loyall';
import _ from 'lodash';
import api from '@/services/api';

export default {
  data() {
    return {
      dialog: false,
      dialogLoading: false,
      funds: [],
      totalFunds: 0,
      overview: {},
      search: '',
      cancel: false,
      open: true,
      isTyping: false,
      footerProps: {
        'disable-items-per-page': true,
      },
      defaultItem: {
        name: '',
        reportName: '',
        document: '',
      },
      editedItem: {
        name: '',
        reportName: '',
        document: '',
      },
      query: '',
      loading: false,
      headers: [
        { text: 'Nome', value: 'name' },
        { text: 'Documento', value: 'document' },
        // { text: 'Adminsitradora', value: 'administrator' },
        { text: 'Gestor', value: 'manager' },
        // { text: 'Benchmark', value: 'benchmark' },
        // { text: 'Data Inicial', value: 'startDate' },
        // {
        //   text: 'Ações', value: 'action', sortable: false, align: 'center',
        // },
      ],
    };
  },
  watch: {
    async cancel() {
      await this.getData(this.page);
    },
    async open() {
      await this.getData(this.page);
    },
    search: _.debounce(function searchWatcher() {
      this.isTyping = false;
    }, 500),
    async isTyping(value) {
      if (!value) {
        if (this.search.length === 0) {
          await this.getData();
          return;
        }
        await this.searchForm(this.search);
      }
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    numberWithCommas(x) {
      if (!x) return '';
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    parseDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    async changePage(page) {
      if (this.query && this.query.length > 0) {
        await this.searchForm(this.query, page);
        return;
      }
      await this.getData(page);
    },
    async getData(page = 1) {
      this.loading = true;
      this.funds = [];
      try {
        const params = {
          sortField: 'name',
          page,
        };

        if (this.cancel) {
          params.canceled = this.cancel;
        }
        const { data: responseFunds } = await api.funds.list({
          params,
        });

        const { data: responseFundsOverview } = await api.funds.overview();
        this.funds = responseFunds.funds;
        this.totalFunds = responseFunds.total;
        this.overview = responseFundsOverview;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
    async searchForm(query, page = 1) {
      this.query = query;
      this.funds = [];
      this.loading = true;
      let queryRest = { query, params: { page } };
      try {
        if (this.cancel !== this.open) {
          queryRest = { query, params: { page, cancel: this.cancel, open: this.open } };
        }
        const response = await api.funds.search(queryRest);
        this.funds = response.data.funds;
        this.totalFunds = response.data.total;
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      this.editedIndex = this.managers.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    async save() {
      try {
        this.dialogLoading = true;
        if (this.editedItem._id) {
          await api.managers.update(this.editedItem._id, this.editedItem);
        } else {
          await api.managers.create(this.editedItem);
        }
        this.$store.dispatch('notification', {
          text: `${this.editedItem.name} atualizado com sucesso!`,
          color: 'success',
          status: true,
        });
        await this.getData();
        this.close();
      } catch (error) {
        this.$store.dispatch('notification', {
          text: error.response ? error.response.data.error.message : error.message,
          color: 'error',
          status: true,
        });
      } finally {
        this.close();
        this.editedItem = this.defaultItem;
      }
    },
    close() {
      this.dialog = false;
      this.dialogLoading = false;
    },
  },
};
</script>
