<template>
  <v-form v-model="isFormValid">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            label="Nome Curto"
            v-maska="vMask.uppercased"
            v-model.trim="formFields.shortName"
            :rules="[vRules.requiredField]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <managers-selector
            label="Gestor"
            item-value="_id"
            item-text="name"
            v-model.trim="formFields.manager._id"
            :rules="[vRules.requiredField]"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <administrators-selector
            label="Administrador"
            item-value="_id"
            item-text="name"
            v-model.trim="formFields.administrator._id"
            :rules="[vRules.requiredField]"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12"> Custódia </v-col>
        <v-col cols="12">
          <custody-account-input
            :initial-data="{
              account: formFields.custodyAccount,
              bank: formFields.custodyBank,
              agency: formFields.custodyAgency,
            }"
            @update="updateCustodyInfo"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-row dense>
            <v-col cols="12"> Dias para Aplicação </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Cotização"
                v-maska="'###'"
                v-model="formFields.purchase.daysToConvertion"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Tipo"
                :items="dayTypeOptions"
                v-model="formFields.purchase.daysToConvertionType"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Liquidação"
                v-maska="'###'"
                v-model="formFields.purchase.daysToSettlement"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Tipo"
                :items="dayTypeOptions"
                v-model="formFields.purchase.daysToSettlementType"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row dense>
            <v-col cols="12"> Dias para Resgate </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Cotização"
                v-maska="'###'"
                v-model="formFields.withdrawal.daysToConvertion"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Tipo"
                :items="dayTypeOptions"
                v-model="formFields.withdrawal.daysToConvertionType"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Liquidação"
                v-maska="'###'"
                v-model="formFields.withdrawal.daysToSettlement"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                label="Tipo"
                :items="dayTypeOptions"
                v-model="formFields.withdrawal.daysToSettlementType"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { uppercased } from '@/utils/mask-utils';
import { requiredField } from '@/utils/validators';

import AdministratorsSelector from '@/components/global/AdministratorsSelector.vue';
import CustodyAccountInput from '@/components/global/CustodyAccountInput.vue';
import ManagersSelector from '@/components/global/ManagersSelector.vue';
import fillSchema from '@/utils/fill-schema';

const formSchema = {
  shortName: null,
  manager: { _id: '' },
  administrator: { _id: null },

  custodyBank: null,
  custodyAgency: null,
  custodyAccount: null,

  purchase: {
    daysToConvertion: null,
    daysToConvertionType: null,
    daysToSettlement: null,
    daysToSettlementType: null,
  },

  withdrawal: {
    daysToConvertion: null,
    daysToConvertionType: null,
    daysToSettlement: null,
    daysToSettlementType: null,
  },
};

export default {
  name: 'FundManagementFormTab',

  props: {
    fund: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: String,
      default: '',
    },
  },

  components: {
    AdministratorsSelector,
    ManagersSelector,
    CustodyAccountInput,
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('change', this.value, this.formFields, this.isFormValid);
        });
      },
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.fund),

    supportFields: {
      custodyAccountNumber: vm.fund.custodyAccount?.split('-')?.[0] ?? null,
      custodyAccountDigit: vm.fund.custodyAccount?.split('-')?.[1] ?? null,
    },

    isFormValid: false,

    vRules: {
      requiredField,
    },

    vMask: {
      uppercased,
    },

    dayTypeOptions: [
      { value: 'BUSINESS_DAYS', text: 'Úteis' },
      { value: 'CALENDAR_DAYS', text: 'Corridos' },
    ],
  }),

  methods: {
    updateCustodyInfo(data) {
      this.$set(this.formFields, 'custodyAccount', data?.account);
      this.$set(this.formFields, 'custodyAgency', data?.agency);
      this.$set(this.formFields, 'custodyBank', data?.bank);
    },
  },
};
</script>
