<template>
  <div>
    <h2 class="text-subtitle-1 mt-6 mb-3">Rentabilidade</h2>
    <v-card class="mt-3 pa-0">
      <v-simple-table class="text-center">
        <thead>
          <tr>
            <th class="text-center primary white-border white--text text-subtitle-1">Ano</th>
            <th class="text-center primary white-border white--text"></th>
            <th class="text-center primary white-border white--text">Jan</th>
            <th class="text-center primary white-border white--text">Fev</th>
            <th class="text-center primary white-border white--text">Mar</th>
            <th class="text-center primary white-border white--text">Abr</th>
            <th class="text-center primary white-border white--text">Mai</th>
            <th class="text-center primary white-border white--text">Jun</th>
            <th class="text-center primary white-border white--text">Jul</th>
            <th class="text-center primary white-border white--text">Ago</th>
            <th class="text-center primary white-border white--text">Set</th>
            <th class="text-center primary white-border white--text">Out</th>
            <th class="text-center primary white-border white--text">Nov</th>
            <th class="text-center primary white-border white--text">Dez</th>
            <th class="text-center primary white-border white--text">Ano</th>
            <th class="text-center primary white-border white--text">Acum.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in rows" :key="index" :class="index > 4 && !showRow ? 'hide-row' : ''">
            <td class="text-subtitle-1 text-center primary white--text white-border">
              {{ row.yearDate }}
            </td>
             <td class="text-caption">
              Fundo <br />
              {{benchmark === "CDI" ? '%' : 'vs'}} {{benchmark}}
            </td>
            <td v-for="(month, index) in months" :key="index">
              <div class="py-2" v-if="row.months[month]">
                <span :class="`mb-1 ${textColor(row.months[month].mtd)}`">{{percent(row.months[month].mtd)}}</span><br />
                <span :class="`mb-1 ${textColor(row.months[month].vsBenchmarkMtd)}`">{{percent(row.months[month].vsBenchmarkMtd, 0)}}</span>
              </div>
              <div class="py-2" v-else>
                -- <br />
                --
              </div>
            </td>
            <td>
              <span :class="`mb-1 ${textColor(row.year.fund)}`">{{percent(row.year.fund)}}</span><br />
              <span :class="`mb-1 ${textColor(row.year.vsBenchmark)}`">{{percent(row.year.vsBenchmark, 0)}}</span>
            </td>
            <td>
              <span :class="`mb-1 ${textColor(row.acumulated.fund)}`">{{percent(row.acumulated.fund)}}</span><br />
              <span :class="`mb-1 ${textColor(row.acumulated.vsBenchmark)}`">{{percent(row.acumulated.vsBenchmark, 0)}}</span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
  </v-card>
  <div v-if="rows.length > 4" class="text-center py-3">
      <v-btn small class="primary" @click.prevent="showRow = !showRow">
        {{showRow ? 'Mostrar menos' : 'Mostrar mais'}}
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss">
  .hide-row {
    display: none;
  }
  .v-application .white-border.primary {
    border: solid 1px #FFF !important;
    &:first-child {
      border-left: 0 !important;
    }
    &:last-child {
      border-right: 0 !important;
    }
  }
</style>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

export default {
  data() {
    return {
      rows: [],
      showRow: false,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      moment,
      benchmark: '',
    };
  },
  props: {
    fundId: String,
  },
  computed: {
  },
  methods: {
    async getData() {
      const { data } = await api.funds.monthly(this.fundId);
      this.benchmark = data.fund.benchmark.name;
      Object.keys(data.returns).forEach((key) => {
        let rowDate;
        Object.keys(data.returns[key].months).forEach((monthKey) => {
          if (data.returns[key].months[monthKey].date && !rowDate) {
            rowDate = data.returns[key].months[monthKey].date;
          }
        });
        if (!rowDate) return;
        [data.returns[key].yearDate] = rowDate.split('-');
        this.rows.push(data.returns[key]);
      });
      this.rows.reverse();
    },
    percent(number, precision = 2) {
      const THRESHOLD = -80;

      const percentage = Number.parseFloat(number) * 100;

      if (!Number.isFinite(percentage) || percentage < THRESHOLD) {
        return '---';
      }

      return `${percentage.toFixed(precision).replace('.', ',')}%`;
    },
    textColor(val) {
      const THRESHOLD = -0.8;
      const number = Number.parseFloat(val);

      if (!Number.isFinite(number) || number < THRESHOLD || number === 0) {
        return '';
      }

      if (number > 0) {
        return 'green--text';
      }

      return 'red--text';
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
