<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          icon
          v-bind="attrs"
          v-on="on"
        >
         <v-icon :color="healthColor">
          mdi-heart-pulse
          </v-icon>
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title
          :class="`text-h5 ${healthColor} lighten-1 d-flex justify-space-between`"
          primary-title
        >
          Health Check
          <v-btn
          @click="run"
          color="blue lighten-2"
           text icon small
          >
          <v-icon color="black">
            mdi-play
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-2">
          Data verificação: {{moment(healthCheck.date).format("DD/MM/YYYY HH:mm:ss")}} <br>
          Verificado até: {{moment(healthCheck.endDate).format("DD/MM/YYYY")}} <br>
          <div v-if="healthCheck.historyReport">
            <h5>Datas Faltando ({{healthCheck.historyReport.missingDates.length}})</h5>
            {{healthCheck.historyReport.missingDates}}
            <h5>Datas Erradas ({{healthCheck.historyReport.wrongDates.length}})</h5>
            {{healthCheck.historyReport.wrongDates}}
          </div>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

export default {
  name: 'FundHealth',

  props: {
    fundId: String,
  },
  data() {
    return {
      healthCheck: {},
      dialog: false,
      loading: false,
    };
  },
  methods: {
    moment,
    async run() {
      this.loading = true;
      const { data: healthCheck } = await api.funds.runHealth(this.fundId);
      this.healthCheck = healthCheck;
      this.loading = false;
    },
  },
  computed: {
    healthColor() {
      if (this.health > 0) {
        return 'green';
      } if (this.health < 0) {
        return 'red';
      }
      return 'blue';
    },
    health() {
      if (this.healthCheck.historyReportErrorsCount > 0) {
        return -1;
      }
      if (this.healthCheck.historyReportErrorsCount === 0) {
        return 1;
      }
      return 0;
    },
  },
  async mounted() {
    const { data: healthCheck } = await api.funds.getHealth(this.fundId);
    this.healthCheck = healthCheck;
  },
};
</script>
