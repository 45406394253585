<template>
  <v-form v-model="isFormValid">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            label="Nome"
            v-model.trim="formFields.reportName"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            label="Tipo"
            v-model.trim="formFields.reportType"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import fillSchema from '@/utils/fill-schema';

const formSchema = {
  reportName: null,
  reportType: null,
};

export default {
  name: 'FundReportFormTab',

  props: {
    fund: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: String,
      default: '',
    },
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('change', this.value, this.formFields, this.isFormValid);
        });
      },
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.fund),

    isFormValid: false,

    dayTypeOptions: [
      { value: 'BUSINESS_DAYS', text: 'Úteis' },
      { value: 'CALENDAR_DAYS', text: 'Corridos' },
    ],
  }),
};
</script>
