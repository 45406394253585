<template>
  <v-container>
    <v-data-table dense hide-default-footer :headers="headers" :items="formFields.externalIds">
      <template v-slot:item.externalId="{ item, index }">
        <v-form
          v-if="editingIndex === index"
          v-model="isExternalIdValid"
          @submit.prevent="patchExternalId"
        >
          <v-text-field
            dense
            autofocus
            hide-details
            placeholder="ID Externo"
            class="tabular-input"
            :rules="[vRules.requiredField]"
            v-model="supportFields.externalId"
          />
        </v-form>
        <span v-else>{{ item.externalId }}</span>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <div v-if="editingIndex === index">
          <v-btn
            small
            icon
            type="submit"
            color="success"
            :disabled="!isExternalIdValid"
            @click="patchExternalId"
          >
            <v-icon small>mdi-check-bold</v-icon>
          </v-btn>
          <v-btn icon small color="error" @click="reset">
            <v-icon small>mdi-close-thick</v-icon>
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            small
            icon
            color="primary"
            :disabled="isCreating || isEditing"
            @click="() => editExternalId(item, index)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            small
            icon
            color="error"
            :disabled="isCreating || isEditing"
            @click="() => deleteExternalId(index)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:body.append v-if="isCreating">
        <tr>
          <td>
            <v-form v-model="isSourceValid" @submit.prevent="createExternalId">
              <v-text-field
                dense
                autofocus
                hide-details
                placeholder="Origem"
                class="tabular-input"
                :rules="[vRules.requiredField, vRules.uniqueSource]"
                v-model="supportFields.source"
              />
            </v-form>
          </td>
          <td>
            <v-form v-model="isExternalIdValid" @submit.prevent="createExternalId">
              <v-text-field
                dense
                hide-details
                placeholder="ID Externo"
                class="tabular-input"
                :rules="[vRules.requiredField]"
                v-model="supportFields.externalId"
              />
            </v-form>
          </td>
          <td class="d-flex justify-end">
            <v-btn
              small
              icon
              type="submit"
              color="success"
              :disabled="!isSourceValid || !isExternalIdValid"
              @click="createExternalId"
            >
              <v-icon small>mdi-check-bold</v-icon>
            </v-btn>
            <v-btn small icon color="error" @click="reset">
              <v-icon small>mdi-close-thick</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-btn
      tile
      small
      width="100%"
      color="primary"
      :solo="isEditing || isCreating"
      :outlined="!isEditing && !isCreating"
      :disabled="isEditing || isCreating"
      @click="addRow"
    >
      <v-icon left>mdi-table-row-plus-after</v-icon>
      Inserir
    </v-btn>
  </v-container>
</template>

<script>
import { requiredField } from '@/utils/validators';

import fillSchema from '@/utils/fill-schema';

const formSchema = {
  externalIds: [],
};

export default {
  name: 'FundIntegrationFormTab',

  props: {
    fund: {
      type: Object,
      default: () => ({}),
    },

    value: {
      type: String,
      default: '',
    },
  },

  watch: {
    formFields: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$emit('change', this.value, this.formFields, this.isFormValid);
        });
      },
    },
  },

  data: (vm) => ({
    formFields: fillSchema(formSchema, vm.fund),

    supportFields: {
      source: null,
      externalId: null,
    },
    editingIndex: null,
    isSourceValid: false,
    isExternalIdValid: false,

    vRules: {
      requiredField,
      uniqueSource: (v) => !vm.formFields.externalIds.find(({ source }) => source === v),
    },

    headers: [
      {
        text: 'Origem',
        value: 'source',
        sortable: false,
        align: 'center',
        width: '170px',
      },
      {
        text: 'ID Externo',
        value: 'externalId',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Ações',
        value: 'actions',
        sortable: false,
        align: 'end',
        width: '100px',
      },
    ],
  }),

  computed: {
    isEditing: (vm) => vm.editingIndex !== null && !vm.isCreating,
    isCreating: (vm) => vm.editingIndex === vm.formFields.externalIds.length,
    isFormValid: (vm) => (!vm.isEditing && !vm.isCreating)
      || (vm.isSourceValid && vm.isExternalIdValid),
  },

  methods: {
    addRow() {
      this.editingIndex = this.formFields.externalIds.length;
    },

    createExternalId() {
      if (!this.isFormValid) return;

      this.formFields.externalIds.push(this.supportFields);
      this.reset();
    },

    editExternalId(item, index) {
      const { source, externalId } = item;

      this.supportFields = { source, externalId };
      this.editingIndex = index;
    },

    patchExternalId() {
      if (!this.isFormValid) return;

      this.$set(this.formFields.externalIds, this.editingIndex, this.supportFields);
      this.reset();
    },

    deleteExternalId(index) {
      this.formFields.externalIds.splice(index, 1);
    },

    reset() {
      this.supportFields = { source: null, externalId: null };
      this.editingIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .tabular-input input {
    text-align: center;
    font-size: 0.875rem;
  }
}
</style>
