<template>
  <v-container grid-list-md>
    <v-row>
      <v-col>
        <h1 class="text-subtitle-1 ma-0">Detalhes do fundo</h1>
      </v-col>
      <v-col>
        <div class="text-right date-select">
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <span
                  v-text="formatDate(startDate)"
                  label="Data inicial"
                  readonly
                  v-on="on"
                ></span>
              </template>
              <v-date-picker v-model="startDate" @input="startDateMenu = false"></v-date-picker>
            </v-menu>
            até
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <span
                  v-text="formatDate(endDate)"
                  label="Data inicial"
                  readonly
                  v-on="on"
                ></span>
              </template>
              <v-date-picker v-model="endDate" @input="endDateMenu = false"></v-date-picker>
            </v-menu>
        </div>
      </v-col>
    </v-row>

    <template v-if="hasFoundFund">
      <BasicInformation
        :fund="fund"
        :fundHistory="fundHistory"
        :loading="loading"
        :user="user"

        @update:fund="() => getData(endDate)"
        @update:quota="() => getData(endDate)"
        @insert:quota="() => getData(endDate)"
      />

      <ReturnTable
        :fundId="fund._id"
      />

      <ReturnChart
        :fundId="fund._id"
        :benchmarkId="fund.benchmark._id"
        :benchmarkName="fund.benchmark.name"
        :endDate="endDate"
        :startDate="startDate"
      />

      <v-row>
        <v-col>
          <DrawdownChart
            :fundId="fund._id"
            :benchmarkId="fund.benchmark._id"
            :benchmarkName="fund.benchmark.name"
            :endDate="endDate"
            :startDate="startDate"
          />
        </v-col>

        <v-col>
          <VolatilityChart
            :fundId="fund._id"
            :benchmarkId="fund.benchmark._id"
            :benchmarkName="fund.benchmark.name"
            :endDate="endDate"
            :startDate="startDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <QuotaholdersPL
            :fundId="fund._id"
            :endDate="endDate"
            :startDate="startDate"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <WithdrawRaisingChart
            :fundId="fund._id"
            :endDate="endDate"
            :startDate="startDate"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<style lang="scss" scoped>
.date-select {
  .v-menu {
    display: inline-block;
  }
  > span {
    cursor: pointer;
    border-bottom: dotted 1px rgba(0,0,0,.87);
  }
}
</style>

<script>
import moment from 'moment-loyall';
import api from '@/services/api';

// Components
import BasicInformation from './BasicInformation.vue';
import ReturnChart from './ReturnChart.vue';
import DrawdownChart from './DrawdownChart.vue';
import VolatilityChart from './VolatilityChart.vue';
import WithdrawRaisingChart from './WithdrawRaisingChart.vue';
import ReturnTable from './ReturnTable.vue';
import QuotaholdersPL from './QuotaholdersPL.vue';

export default {
  name: 'FundDetails',

  components: {
    BasicInformation,
    ReturnChart,
    DrawdownChart,
    VolatilityChart,
    WithdrawRaisingChart,
    ReturnTable,
    QuotaholdersPL,
  },

  data: () => ({
    startDateMenu: false,
    startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),

    endDateMenu: false,
    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),

    id: '',
    fund: null,
    fundHistory: [],

    loading: true,
  }),

  computed: {
    user: (vm) => vm.$store.getters.user,
    hasFoundFund: (vm) => vm.fund && Object.keys(vm.fund).length > 0,
  },

  watch: {
    async endDate(date) {
      await this.getData(date);
    },
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    async getData(date) {
      this.loading = true;
      this.id = this.$route.params.id;

      const { data: { fund } } = await api.funds.byId(this.id, {
        params: {
          date,
        },
      });

      this.fund = fund;

      const { data: { history: fundHistory } } = await api.funds.returnsById(this.id, {
        params: {
          endDate: this.endDate,
          startDate: this.startDate,
        },
      });

      fundHistory.sort((a, b) => new Date(b.date) - new Date(a.date));
      fundHistory.forEach((history) => {
        if (history.value && history.quotaholders && !this.fund.lastCompleteInfo) {
          this.fund.lastCompleteInfo = history;
        }
      });

      this.fundHistory = fundHistory;

      this.loading = false;
    },
  },
};
</script>
