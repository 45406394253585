<template>
  <div>
    <h2 class="text-subtitle-1 mt-3 mb-3">Gráfico de Rentabilidade</h2>
    <v-card :loading="loading" class="mt-3">
      <v-card-text class="pa-0">
        <apexchart width="100%" height="400" type="area" :options="options" :series="series"></apexchart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { prod, bignumber } from 'mathjs';
import Decimal from 'decimal.js';
import moment from 'moment-loyall';
import _ from 'lodash';
import pt from 'apexcharts/dist/locales/pt-br.json';
import api from '@/services/api';

export default {
  props: {
    fundId: String,
    benchmarkId: String,
    benchmarkName: String,
    endDate: String,
    startDate: String,
  },
  data: () => ({
    series: [],
    history: [],
    fundHistoryData: [],
    benchmarkHistory: [],
    dataCategory: [],
    benchmarkHistoryData: [],
    loading: true,
    options: {
      chart: {
        locales: [pt],
        defaultLocale: 'pt-br',
        animations: {
          enabled: false,
          animateGradually: {
            enabled: false,
          },
          dynamicAnimation: {
            enabled: false,
          },
        },
        zoom: {
          type: 'x',
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        line: {
          curve: 'smooth',
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: 'full',
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          // opacityFrom: 0.5,
          // opacityTo: 0,
          // stops: [0, 90, 100],
        },
      },
      stroke: {
        show: true,
        curve: 'straight',
        // lineCap: 'butt',
        // colors: undefined,
        width: 2,
      },
      yaxis: {

        title: {
          text: '',
        },
        labels: {
          formatter: (val) => parseFloat(val).toFixed(0),
        },
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'MM/yyyy',
            month: 'MM/yyyy',
            day: 'dd/MM/yyyy',
            hour: 'HH:mm',
          },
        },
      },
      tooltip: {
        shared: true,
        x: {
          show: true,
          format: 'dd/MM/yyyy',
          // format: 'dd/MM/yyyy HH:mm',
          formatter: undefined,
        },
        y: {
          formatter(val) {
            return `${val.toFixed(2)}%`;
          },
        },
      },
    },
  }),
  watch: {
    startDate() {
      this.getData();
    },
    endDate() {
      this.getData();
    },
  },
  methods: {
    updateChart() {
      const benchData = this.benchmarkName === 'CDI' ? this.prodCalc() : this.benchmarkHistoryFn();
      const fundData = this.historyFn();

      this.series = [
        {
          data: fundData,
          name: 'Fundo',
        },
        {
          data: benchData,
          name: this.benchmarkName,
        },
      ];
    },
    async getData() {
      try {
        if (!this.fundId && !this.benchmarkId) return;
        this.loading = true;

        // Get Fund information
        const {
          data: { history: fundHistory },
        } = await api.funds.returnsById(this.fundId, {
          params: {
            endDate: this.endDate,
            startDate: this.startDate,
          },
        });

        // Get benchmark information
        const {
          data: { history: benchmarkHistory },
        } = await api.indexes.returnsById(this.benchmarkId, {
          params: {
            endDate: moment(fundHistory[fundHistory.length - 1].date).tz('UTC').format('YYYY-MM-DD'),
            startDate: moment(fundHistory[0].date).tz('UTC').format('YYYY-MM-DD'),
          },
        });

        const normalizedFundHistory = _.intersectionBy(fundHistory, benchmarkHistory, 'date');
        const normalizedBenchmarkHistory = _.intersectionBy(benchmarkHistory, normalizedFundHistory, 'date');

        this.history = normalizedFundHistory;
        this.benchmarkHistory = normalizedBenchmarkHistory;

        // Update the chart
        this.updateChart();
        this.loading = false;
      } catch (error) {
        throw Error(error);
      }
    },
    prodCalc() {
      const data = [];
      const performance = [1];
      let totalPerformance = 0;

      this.benchmarkHistory.forEach((input, i) => {
        if (i > 0) {
          performance.push(bignumber(new Decimal(input.dailyChange).plus(1)));
          totalPerformance = prod(performance).minus(1).mul(100);
        }

        this.dataCategory.push(moment(input.date).unix());

        data.push({
          x: moment.tz(input.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
          y: totalPerformance.toPrecision(8),
        });
      });
      return data;
    },
    historyFn() {
      const data = [];
      const performance = [1];
      let totalPerformance = 0;

      this.history.forEach((input, i) => {
        if (i > 0) {
          performance.push(bignumber(new Decimal(input.performance).plus(1)));
          totalPerformance = prod(performance).minus(1).mul(100);
        }

        this.dataCategory.push(moment(input.date).unix());

        data.push({
          x: moment.tz(input.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
          y: totalPerformance.toPrecision(8),
        });
      });
      return data;
    },
    benchmarkHistoryFn() {
      const data = [];
      let totalPerformance = new Decimal(0);

      this.benchmarkHistory.forEach((input) => {
        totalPerformance = new Decimal(input.value)
          .div(new Decimal(this.benchmarkHistory[0].value))
          .minus(1)
          .mul(100);

        data.push({
          x: moment.tz(input.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
          y: totalPerformance.toPrecision(8),
        });
      });

      return data;
    },
    benchmarkHistoryFnTax() {
      const data = [];
      const newArr = [];
      this.benchmarkHistory.forEach((input) => {
        if (input.change) newArr.push(new Decimal(String(input.change)).add(1).toString());
      });
      newArr.reverse();
      this.benchmarkHistory.forEach((input, i) => {
        const arr = newArr.slice(0, i + 1);
        const perf = new Decimal(prod(arr)).minus(1).mul(100);

        data.push({
          x: moment.tz(input.date, 'America/Sao_Paulo').add(4, 'hours').startOf('day'),
          y: perf.toPrecision(8),
        });
      });

      return data;
    },
  },
  async created() {
    await this.getData();
  },
};
</script>
